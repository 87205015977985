@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;500;600;700;800;900&display=swap');

p,
span {
  margin: 0;
}

.group--actions > *:last-child {
  display: none;
}
.modal-container {
  z-index: 10000;
  /* display: none; */
}

.modal {
  width: 800px;
}

.header p {
  margin: 0;
}

.icon {
  width: 20px;
  height: 20px;
}

.grid-item,
.grid-subItem {
  /* padding: 2px 6px; */
  border: solid 1px #b9b9b9bd;
}

.grid-item {
  background-color: #f0f0f0;
}

.item-title {
  border-right: 1px solid #b9b9b9;
}

.disconnected {
  color: #f50057;
}
.gray {
  background-color: #6b7586;
}
.dark-gray {
  background-color: #242730;
}
.primary-border {
  border: 1px solid #b9b9b9;
}
.primary-border-t {
  border-top: 1px solid #b9b9b9;
}
.primary-border-l {
  border-left: 1px solid #b9b9b9;
}
.primary-border-r {
  border-right: 1px solid #b9b9b9;
}
.primary-border-b {
  border-bottom: 1px solid #b9b9b9;
}
.secondary-background,
.session-item:hover {
  background: #f5f5f5;
}
.isDisabled {
  background-color: #b9b9b9fe !important;
  cursor: default;
}
.tooltip-container:hover .tooltip {
  display: block;
  border-width: 1px;
  border-radius: 3px;
  padding: 8px 21px;
  pointer-events: none;
  z-index: 99999;
  background-color: #3a414c;
  color: #f0f0f0;
  border: 1px solid transparent;
  font-size: 10px;
  font-weight: 400;
  padding: 7px 18px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  position: absolute;
  left: 0px;
  top: 100%;
  opacity: 1;
  transform: translateX(calc(-50% + 10px));
  transition: opacity 0.5s ease-in-out;
}

.tooltip-container .tooltip,
.tooltip-container .bottomtooltip {
  opacity: 0;
}

.tooltip-container:hover .toptooltip {
  display: block;
  border-width: 1px;
  border-radius: 3px;
  pointer-events: none;
  z-index: 99999;
  background-color: #3a414c;
  color: #f0f0f0;
  border: 1px solid transparent;
  font-size: 10px;
  font-weight: 400;
  padding: 7px 18px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  position: absolute;
  left: 0px;
  opacity: 1;
  transform: translateX(calc(-50% + 30px));

  transition: opacity 0.5s ease-in-out;
  top: -13px !important;
  margin-left: 80px;
}
.tooltip-container:hover .toptooltip::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transform: rotate(90);
  top: 50%;
  left: -2;
  margin-left: -10px;
}
.tooltip-container:hover .toptooltip::after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  top: 25%;
  left: 0;
  margin-left: -6px;
  transform: rotate(90);
  border-right-style: solid;
  border-right-width: 6px;
  border-right-color: #3a414c;
}
.tooltip-container:hover .bottomtooltip {
  display: block;
  border-width: 1px;
  border-radius: 3px;
  padding: 8px 21px;
  pointer-events: none;
  z-index: 999;
  background-color: #3a414c;
  color: #f0f0f0;
  border: 1px solid transparent;
  font-size: 10px;
  font-weight: 400;
  padding: 7px 18px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  position: absolute;
  left: 0px;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}
.tooltip-container:hover .tooltip::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: -8px;
  left: 50%;
  margin-left: -10px;
}
.tooltip-container:hover .tooltip::after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top: -6px;
  left: 50%;
  margin-left: -8px;

  border-bottom-style: solid;
  border-bottom-width: 6px;
  border-bottom-color: #3a414c;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.bg-modalLoading {
  background-color: #fefffebf;
}
.session-header {
  min-height: 56px;
  max-width: 56px;
  line-height: 56px;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
}
.z-first {
  z-index: 9999999999999;
}

.databaseDropList {
  font-size: 11px;
  border-radius: 2px;
  color: #a0a7b4;
  height: 34px;
}

.datasetDropList {
  font-size: 11px;
  border-radius: 2px;
  color: #a0a7b4;
  height: 30px;
}
.databaseDropList > div.selectedArea {
  font-size: 11px;
  background-color: #29323c;
  border-radius: 2px;
  color: #a0a7b4;
  padding: 4px 10px;
  height: 100%;
}
.datasetDropList > div.selectedArea {
  font-size: 11px;

  border-radius: 2px;
  color: #a0a7b4;
  padding: 4px 10px;
  height: 100%;
}
.noSelect {
  user-select: none;
}
.databaseButton {
  font-size: 11px !important;

  padding: 9px 12px;
  height: 32px;
}
.databaseDropList .databaseName {
  font-size: 11px;
  background-color: #363f4a;

  color: #a0a7b4;
  padding: 4px 10px;
  height: 100%;
}
.datasetDropList .databaseName {
  font-size: 11px;
  background-color: #363f4a;

  color: #a0a7b4;
  padding: 4px 10px;
  height: 100%;
}
.databaseDropList .tableName {
  font-size: 11px;
  background-color: #29323c;

  color: #a0a7b4;
  padding: 4px 10px;
  height: 100%;
}
.datasetDropList .tableName {
  font-size: 11px;
  background-color: #29323c;

  color: #a0a7b4;
  padding: 4px 10px;
  height: 100%;
}
.databaseDropList .options {
  background-color: #3a414c;
}

.databaseDropList .options p {
  height: 26px;
}

.datasetDropList .options {
  background-color: #3a414c;
}

.datasetDropList .options p {
  height: 26px;
}
.selectedDatasetName {
  position: absolute;
  top: 3px;
  font-size: 8px;
}
.selectedDatabaseName {
  position: absolute;
  top: 3px;
  font-size: 8px;
}
.selectedTableName {
  margin-top: 11px;
}
.primary-green {
  background-color: #0f9668;
}
.sidebar-button {
  background-color: #0f9668;
}
.primary-green:hover {
  background-color: #4ca99d;
}

.query-builder-container {
  padding: 0 !important;
}

.query-builder {
  margin: 10px 0 0 0;
}
.group-container {
  background-color: #f5f5f5 !important;
  border: 1px solid #f5f5f5;
}
.group {
  background-color: transparent;
  border: none;
  border-radius: 5px;
}
.rule_group {
  background-color: white;
  border: 1px solid transparent;
  padding-left: 0 !important;
}

.group--children {
  padding-left: 18px !important;
}

.widget--widget {
  width: 120px;
}
.group--actions {
  opacity: initial !important;
}
.qb-lite {
  background-color: transparent;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
}
.geocoder-input input {
  padding: 4px 4px 4px 36px !important;
  width: 360px;
}

.step {
  position: relative;
}

.step::before {
  content: '';
  position: absolute;
  left: 12px;
  top: 24px;
  height: 100%;
  width: 1px;
  background-color: #aeaeae;
  border: 1px solid #aeaeae;
  margin-block: 10px;
}

.theight {
  transition: max-height 0.3s ease-in-out;
}

.rule--body--wrapper select,
.rule--body--wrapper input,
.group--field select {
  border: solid 1px gray;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px !important;
  margin-block: 5px;
}
.widget--valuesrc {
  opacity: 1 !important;
  font-size: 12px !important;
  padding-right: 10px;
}
.rule {
  padding: 0 !important;
}
.rule--widget--BOOLEAN .widget--widget > input {
  margin-right: 4px;
  vertical-align: middle;
}
.rule--widget--BOOLEAN .widget--widget > label {
  margin-right: 10px;
  vertical-align: middle;
}
.group-or-rule-container {
}
.group--children {
  margin: 0 !important;
}
.group--drag-handler,
.rule--drag-handler {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
input[type='datetime-local'] {
  max-width: 147px;
}
.__react_component_tooltip {
  padding: 8px !important;
}
.hiddenImportant {
  display: none !important;
}

.rangeslider-horizontal {
  height: 4px;
  border-radius: 0;
  box-shadow: none;
  background-color: #3a414c;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #6a7485;
  border-radius: 2px;
  box-shadow: none;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 12px;
  height: 12px;
  border-radius: 0;
  background-color: #d3d8e0;
  box-shadow: none;
}
.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}
.rangeslider__labels,
.rangeslider__handle-tooltip {
  display: none !important;
}
.fixed-position {
  position: fixed;
}
.fixed {
  position: initial !important;
}
.query-builder-container {
  color: #000;
}
.full-flex {
  flex: 1 1 100%;
}
.c-card img {
  transition: transform 0.3s ease-in-out;
}
.c-card:hover img {
  transform: scale(1.05);
}

.setting-modal {
  z-index: 100000;
}
.layer-config-group__content__collapsible {
  max-height: 100% !important;
}
.collapsed .layer-config-group__content .layer-config-group__content__collapsible {
  max-height: 0 !important;
}

.results-table-wrapper .body-grid {
  padding: 0 16px;
  overflow: auto !important;
}
.group--conjunctions input {
  margin-left: 10px;
  margin-right: 10px;
}
.map-legend {
  max-height: 300px;
}
.data-table-container .align-right {
  text-align: left !important;
  align-items: start !important;
}
.ReactVirtualized__Grid.body-grid {
  margin-top: 1px;
}
.ReactVirtualized__Grid.header-grid {
  padding-right: 20px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  padding-bottom: 2px;
  padding-right: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6a7485;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.results-table-wrapper .body-grid {
  padding: 0px !important;
}
.unpinned-columns.grid-column {
  margin-bottom: 2px;
}
